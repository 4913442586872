import React from 'react'
import Banner from '../Components/Banner';
import { newsData } from '../data/newsData';
import Carousel from 'react-bootstrap/Carousel';
import newsBanner from "../imges/news-banner.png";

function NewsAndEvents() {
  return (
    <div>
      <div>
        <Banner title={'Latest News & Events'} image={newsBanner} />
      </div>
      <div className='nevents'>
        <div className='newsevents'>

          <div className="custom-container">
            <h2 className="heading-text ">Latest News &amp; Events</h2>

            {newsData.map((item, index) => {
              return (
                <div className="row">
                  <div className="col-12 col-sm-5 col-md-4">

                    <Carousel>
                      {item.image.map(image => {
                        return (
                          <Carousel.Item>
                            <img src={image} className='w-100 n-img' alt="" />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                  <div className="col-12 col-sm-7 col-md-8">
                    <h3 className="heading-text"></h3>
                    <h2 class="img-text">{item.title}</h2>
                    <p class="img-para">{item.description}</p>
                    <div class="read-text"><a class="read-more" href={`/newsDetails/${item.id}`}>Read more </a><a class="read-more" href="/#pricing"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" font-size="20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg> </a></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      </div>
    </div>
  )
}

export default NewsAndEvents