import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import peoples from "../imges/peoples.png";
import { homeSlideData } from "../data/homeSlider";
import { Link } from "react-router-dom";

function Headslide() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <section className="headslid">
        <Slider {...settings}>
          {homeSlideData.map((item, index) => {
            return (

              <div className="bg-gree" >
                  <div className="row">
                    <div className="col-12 col-sm-6 b">
                      <div className="box-1 bg-green">
                        <div className="about-text">
                          <h2 className="heading">{item.title}</h2>
                          <p className="para">{item.description}</p>
                          <Link to="/" className="btn btnn btn-h bg-green text-white">
                            Learn More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 b-2">
                      <div>
                        <img src={item.image} className="peoples-img w-100" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
            )
          })}


        </Slider>
      </section>
    </div>
  );
}

export default Headslide;
