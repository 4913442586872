import React from "react";
import Banner from "../Components/Banner";
// import Text from '../Components/Hero'
import Hero from "../Components/Hero";
// import { Link } from 'react-router-dom';

function Womens() {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <Hero />
      </div>
    </div>
  );
}

export default Womens;
