import React from "react";
import logo from "../imges/logo.png";
import { Link } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { BiLogoTiktok } from "react-icons/bi";
export default function Footer() {
  return (
    <div>
      <section className="footer">
        <div className="custom-container">
          <div className="row row-1">
            <div className="col-12 col-md-9">
              <div className="row">
                <div className="col-12  col-md-3 box">
                  <ul>
                    <li>
                      <Link
                        to="https://docs.google.com/forms/d/e/1FAIpQLSdOHxWMlNbRsj9RM0Toui82KjADljTJXOKs_Hv4UI13G5s5Ew/viewform?usp=sf_link "
                        className="nav-link f-heading"
                        target="blank"
                      >
                        What we do
                      </Link>
                    </li>
                    <li>
                      <Link to="/goals " className="nav-link f-content">
                        Goals
                      </Link>
                    </li>
                    <li>
                      <Link to="/programs " className="nav-link f-content">
                        Programs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/newsandevents "
                        className="nav-link f-content "
                      >
                        News & Events
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 box">
                  <ul>
                    <li>
                      <Link to="/membership" className="nav-link f-heading">
                        Membership
                      </Link>
                    </li>
                    <li>
                      <Link to="/membership " className="nav-link f-content">
                        General Members
                      </Link>
                    </li>
                    <li>
                      <Link to="/becomeAMember " className="nav-link f-content">
                        Become A Member
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 box">
                  <ul>
                    <li>
                      <Link to="/about " className="nav-link f-heading">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus " className="nav-link f-content">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/executive " className="nav-link f-content">
                        Executive Members
                      </Link>
                    </li>
                    <li>
                      <Link to="/collaborator " className="nav-link f-content ">
                        Collaborators
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 box">
                  <ul>
                    <li>
                      <Link to="/donate " className="nav-link f-heading">
                        Donations
                      </Link>
                    </li>
                    <li>
                      <Link to="/donate " className="nav-link f-content">
                        Donate
                      </Link>
                    </li>
                    <li>
                      <Link to="/donors" className="nav-link f-content">
                        Our Donnors
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <Link to="#pricing " className="follow">
                <h4 className="f-heading text-gren">Follow Us</h4>
              </Link>
              <ul className="d-flex">
                <li>
                  <Link
                    to="https://www.facebook.com/profile.php?id=61552403410850&mibextid=LQQJ4d "
                    target="blank"
                  >
                    <FaFacebook color="green" />
                  </Link>
                </li>

                <li>
                  <Link
                    to="https://www.instagram.com/aqeeq_foundation "
                    target="blank"
                  >
                    <RiInstagramFill color="green" />
                  </Link>
                </li>

                <li>
                  <Link
                    to="https://youtube.com/@AqeeqFoundation?si=WtFDJi-g91FafAPi "
                    target="blank"
                  >
                    <IoLogoYoutube color="green" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.tiktok.com/@aqeeq.foundation?_t=8grj9qYCKVm&_r=1 "
                    target="blank"
                  >
                    <BiLogoTiktok color="green" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row row-2">
            <div className="col-12 col-md-6">
              <div className="text-box">
                <Link to="/" className="text-decoration-none">
                  <img src={logo} className="logo-img" alt="" />
                  <strong>Aqeeq Foundation</strong>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <p className="text">
                © 2023 Aqeeqa Foundation. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
