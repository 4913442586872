

import lecture from "../imges/data/programs/lecture.jpg";
import BoySpeech from "../imges/data/newsandevents/BoySpeech.jpg";
import chart from "../imges/data/newsandevents/chart.jpg";
import ChildClass from "../imges/data/newsandevents/ChildClass.jpg";
import ChildClassroom from "../imges/data/newsandevents/ChildClassroom.jpg";
import ChildLine from "../imges/data/newsandevents/ChildLine.jpg";
import childs from "../imges/data/newsandevents/childs.jpg";
import classes from "../imges/data/newsandevents/classes.jpg";
import study from "../imges/data/programs/study.jpg";
import GirlSpeech from "../imges/data/newsandevents/GirlSpeech.jpg";
import hand from "../imges/data/newsandevents/hand.jpg";
import lecturee from "../imges/data/newsandevents/lecturee.jpg";
import meet from "../imges/data/newsandevents/meet.jpg";
import meeting from "../imges/data/newsandevents/meeting.jpg";
import paper from "../imges/data/newsandevents/paper.jpg";
import shower from "../imges/data/newsandevents/shower.jpg";
import student from "../imges/data/newsandevents/student.jpg";
import StudentClass from "../imges/data/newsandevents/StudentClass.jpg";
import team from "../imges/data/newsandevents/team.jpg";
import test from "../imges/data/newsandevents/test.jpg";
import wash from "../imges/data/newsandevents/wash.jpg";





export const newsData = [
    {
        id: '1',
        title: 'Women Empowerment',
        description: 'Breast cancer awareness session in school',
        image: [lecturee, lecture]
    },
    {
        id: '2',
        title: 'Awareness Session',
        description: 'Awareness on breast cancer session in UOH',
        image: [chart, meet, meeting]
    },
    {
        id: '3',
        title: 'School Health',
        description: 'Team Aqeeq foundation session in UFH Primary School WAH',
        image: [ChildClass, ChildClassroom, ChildLine]
    },
    {
        id: '4',
        title: 'PIAS Academy Session',
        description: 'Awareness session in Pias academy',
        image: [classes, StudentClass]
    },
    {
        id: '5',
        title: 'INFECTION , PREVENTION AND CONTROL',
        description: 'Awareness session on hand hygiene in Tariq Ideal School wah',
        image: [study, wash, childs, hand, shower]
    },
    {
        id: '6',
        title: 'HEALTH EDUCATION',
        description: 'Health session in university of Haripur',
        image: [student]
    },
    {
        id: '7',
        title: 'MOU Signing Ceremony',
        description: 'We congratulate to Aqeeq Foundation on signing MOU with Chaudhary Hospital in District Haripur',
        image: [BoySpeech, GirlSpeech]
    },
    {
        id: 8,
        title: 'HEALTH EDUCATION',
        description: 'Health session in university of Haripur',
        image: [test, paper, team]

    },
    {
        id: 9,
        title: 'HEALTH EDUCATION',
        description: 'Health session in university of Haripur',
        image: [team]
    },
]
