import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import boy1 from "../imges/boy1.png";
import LightGrey from "../imges/LightGrey.png";
import Grey from "../imges/Grey.png";
import BlackHairGirl from "../imges/BlackHairGirl.png";

function AdviserSlide() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="adviser-slide bg-grey color-c">
      <div className="slide-section">
        <div className="slider-container">
          <div className="custom-container">
            <h2 className="heading ">Professionals</h2>
            <Slider {...settings}>
              <div className="card bg-grey">
                <img src={boy1} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
              <div className="card bg-grey">
                <img src={LightGrey} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
              <div className="card bg-grey">
                <img src={BlackHairGirl} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
              <div className="card bg-grey">
                <img src={Grey} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
              <div className="card bg-grey">
                <img src={boy1} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
              <div className="card bg-grey">
                <img src={LightGrey} alt="" />
                <div className="card-body">
                  <h3 className="img-text">Jackson Gil (MBBS)</h3>
                  <p className="img-para">Associate Professor</p>
                  <p className="text">
                    Biostatistics, School of Public Health <br /> and Preventive
                    Medicine, Monash <br /> University,Australia
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdviserSlide;
