import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import publishimg1 from "../imges/publishimg1.png";
import publishimg2 from "../imges/publishimg2.png";
import publishimg3 from "../imges/publishimg3.png";
import publishimg4 from "../imges/publishimg4.png";
import atribution from "../imges/atribution.png";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { newsData } from "../data/newsData";



function NewsSlide() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="news-section bg-grey">
      <div className="slider-container">
        <h2 className="heading ">Latest News & Events</h2>

        <div className="card-boxes">
          <Slider {...settings}>

            {newsData.map((item, index) => {
              return (
                <div className="card">
                  <div className="img-box">
                    <img src={item.image[0]} className="news-img" alt="" />
                  </div>
                  <div className="card-body">
                    <h3 className="img-text">{item.title}"</h3>
                    <p className="img-para">{item.description}</p>
                    <div className="read-text">
                      <Link to={`/newsDetails/${item.id}`} className="read-more">
                        Read more{" "}
                      </Link>
                      <Link to="" className="read-more">
                        <FiArrowUpRight fontSize={20} />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}


          </Slider>
        </div>
      </div>
    </div>
  );
}

export default NewsSlide;
