import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { teamMember } from "../data/teamMembers";




function TeamSlide() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slide-section bg-grey">
      <div className="slider-container">
        <div className="custom-container">
          <h2 className="heading ">Our Team</h2>
          <Slider {...settings}>
            {teamMember.map((item, index) => {
              return (
                <div className="card bg-grey">
                  <img src={item.image} alt={item.name} />
                  <div className="card-body">
                    <h3 className="img-text">{item.name}</h3>
                    <p className="img-para">{item.profession}</p>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default TeamSlide;
