

//programs
import lecture from "../imges/data/programs/lecture.jpg";
import flex from "../imges/data/programs/flex.jpg";
import study from "../imges/data/programs/study.jpg";
import education from "../imges/data/programs/education.jpg";

export const programsData = [
    {
        title: 'Women Health Empowerment ',
        description: '( "Our foundation is inspired by a future where women everywhere embrace their full potential, standing as empowered leaders, catalysts of change, and architects of their own destinies. Guided by principles of equality, inclusivity, and opportunity, our women empowerment program strives to break down barriers, promote education and skills development, and create a world where every woman has the resources and support to thrive. Through collaborative efforts, advocacy, and empowerment initiatives, we aspire to build a society where womens voices are heard, their contributions recognized, and their empowerment becomes a beacon of progress forgenerations to come.")',
        image: lecture
    },
    {
        title: 'School Health',
        description: '("Our foundation envisions a future where every student thrives in a healthy and supportive school environment, laying the foundation for a lifetime of well-being. Guided by a commitment to holistic health, education, and community engagement, our school health program strives to create schools that nurture physical and mental wellness. We aim to empower students with the knowledge and habits for a healthy lifestyle, fostering a generation that not only excels academically but also embraces lifelong well-being. Through collaborative efforts, advocacy, and innovative health initiatives, we aspire to build a world where schools are vibrant hubs of health, learning, and resilience.")',
        image: flex
    },
    {
        title: 'Infection Prevention and Control',
        description: '(Our foundation dreams of a planet where societies demonstrate resilience in the face of infectious threats, and individuals possess the knowledge and resources to protect their well-being. Anchored in principles of excellence, collaboration, and equity in public health, our infection prevention and control program is dedicated to shaping a worldwide landscape where the transmission of infectious diseases is reduced to a minimum.By actively promoting education, pioneering innovative solutions, and fostering community engagement, we aim to cultivate a global ethos that places prevention at the forefront, recognizes the importance of preparedness, and guarantees the health and safety of individuals and communities in the face of infectious challenges.")',
        image: study
    },
    {
        title: 'Health Education',
        description: '("Our foundation imagines a future in which health education serves as a fundamental pillar in every community, providing individuals with the knowledge and skills to make well-informed decisions about their well-being. Grounded in our dedication to accessibility, collaboration, and health equity, our program is committed to shaping a global landscape where all individuals, irrespective of their background, have access to comprehensive and culturally sensitive health information. Through dynamic educational initiatives, creative outreach methods, and collaborative community partnerships, we aim to cultivate a society where individuals actively take charge of their health, playing integral roles in fostering a collective culture of well-being and vitality.")',
        image: education
    }
]