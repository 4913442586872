import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../imges/logo.png'
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" className="navbar">
      <Container className='custom-container'>

        <Link to="/" className='text-decoration-none d-flex align-items-center'>
          <img src={logo} className='logo-img' alt="" />
          <div className="logo-box">
            <h2 className="logo-text">AQEEQ </h2>
            <h3 className="logo-t text-black">
              FOUNDATION
            </h3>
          </div>
        </Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">

            <NavDropdown title="About" id="collapsible-nav-dropdown " className='collapsed show'>
              <NavDropdown.Item className='nav-link nav-drop'>
                <Link to="/about" className='nav-link'>About</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className='nav-link nav-drop'>
                {/* <Link to="/advisors" className='nav-link'>Board Of Advisors</Link> */}
                <Link to="/executive" className='nav-link'>Executive Members</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className='nav-link nav-drop'>
                {/* <Link to="/professional" className='nav-link'>Professionals</Link> */}
                <Link to="/collaborator" className='nav-link'>Collaborators</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className='nav-link nav-drop'>
                <Link to="/donors" className='nav-link'>Donors</Link>
              </NavDropdown.Item>
            </NavDropdown>



            <Link to="/goals" className='nav-link'>Goals</Link>

            <NavDropdown title="Membership" id="collapsible-nav-dropdown">
              <NavDropdown.Item className='nav-link nav-drop'>
                <Link to="/membership" className='nav-link drop-btn'>General Members</Link>
              </NavDropdown.Item>
              <NavDropdown.Item className='nav-link nav-drop'>
                <Link to="/becomeAMember" className='nav-link drop-btn'>Become a Member</Link>
              </NavDropdown.Item>
            </NavDropdown>

            <Link to="/programs" className='nav-link drop-btn'>Programs</Link>
            <Link to="/projects" className='nav-link drop-btn'>Projects</Link>

            <Link to="/newsandevents" className='nav-link'>News & Events</Link>
            <Link to="/publications" className='nav-link'>Publications</Link>
            <Link to="/opportunities" className='nav-link'>Opportunities</Link>
            <Link className='nav-link' to="/contactus">Contact</Link>
          </Nav>
          <Nav className='gap-3'>
            <Link to="/donate" variant="outline-succes" className='nav-btn  text-white bg-green nav-btn-2'>Donate</Link>{' '}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;