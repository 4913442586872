import React from "react";
import Banner from "../Components/Banner";
import AdviserSlide from "../Components/AdviserSlide";

function Advisors() {
  return (
    <div>
      <div className="b">
        <Banner />
      </div>
      <AdviserSlide />
    </div>
  );
}

export default Advisors;
