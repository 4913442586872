import React from "react";
import { MdForwardToInbox } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { BiLogoTiktok } from "react-icons/bi";
import { RiInstagramFill } from "react-icons/ri";

function TopBar() {
  return (
    <div className="bg-green">
    <div className="custom-container ">
    <div className="topbar">
    
        <div className="row align-items-center">
          <div className="  col-5 py-1">
            <MdForwardToInbox />
            <span className="inbox-text">info@aqeeqfoundation.com</span>
          </div>
          <div className=" col-7 ">
            <div className="head-social">
                <ul className="list">
                    <li>
                    <Link to="https://www.facebook.com/profile.php?id=61552403410850&mibextid=LQQJ4d " target="blank">
                    <FaFacebook color="white"/>
                      </Link>
                    </li>
                    <li>
                    <Link to="https://www.instagram.com/aqeeq_foundation "target="blank" >
                    <RiInstagramFill color="white" />
                      </Link>
                    </li>
                    <li>
                    <Link to="https://youtube.com/@AqeeqFoundation?si=WtFDJi-g91FafAPi "target="blank" >
                    <IoLogoYoutube color="white" />

                      </Link>
                    </li>
                    <li>
                    <Link to="https://www.tiktok.com/@aqeeq.foundation?_t=8grj9qYCKVm&_r=1 " target="blank">
                    <BiLogoTiktok color="white" />


                      </Link>
                    </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default TopBar;
