import React from "react";
import Banner from "../Components/Banner";
import MemberSlide from "../Components/MemberSlide";
import Card from "react-bootstrap/Card";
import { executive } from "../data/teamMembers";

function Executive() {
  return (
    <div>
      {/* <div className="b">
        <Banner />
      </div> */}


      <div className="heading-sec">
        <div className="h-box-1">
          <div className="custom-container">
            <h3 className="heading text-green">Executive Members</h3>
          </div>
        </div>
      </div>



      <div className="h-box-2">
        <div className="about-card-section">
          <div className="custom-container">
            <div className="row">
              {executive.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 ">
                    <Card className="text-center">
                      <img src={item.image} alt="" />
                      <Card.Body>
                        <Card.Title className="color-blue">
                          {""}
                          {item.name}
                        </Card.Title>
                        <div className="card-para color-blue">
                          {""}
                        </div>
                        {/* <Card.Text className="color-blue">
                          Biostatistics, School of Public Health <br /> and
                          Preventive Medicine, Monash <br /> University,Australia
                        </Card.Text> */}
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}


            </div>
          </div>
        </div>
        {/* <div class="about-btnn">
          <div class="btn-box">
            <Link to="/goals" className='btn bg-green text-white btn-2'>View More</Link>
          </div>
        </div> */}
      </div>

    </div>
  );
}

export default Executive;