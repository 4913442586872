import React from "react";
import Banner from "../Components/Banner";
import DonorsSlide from "../Components/DonorsSlide";

function Donors() {
  return (
    <div>
      {/* <div className="b">
        <Banner />
      </div> */}
      <DonorsSlide />
    </div>
  );
}

export default Donors;