import React from "react";
import { Link } from 'react-router-dom';
import Banner from "./Banner";
import opportunities from "../imges/opportunities.png";


function Opportunities() {
  return (
    <div>
      <div>
        <Banner title={'Opportunities'} image={opportunities} />

      </div>
      <div className="opportunities">
        <div className="custom-container">
          <div className="row row-1">
            <div className="col-12 col-md-6 box ">
              <h2 className="h-text">
                Research Specialist- Institute for Global Health and Development
              </h2>
              <p className="p-text">
                Vacancy Announcement Global Public Health Research Foundation
                (GPHRF) is a non-profit organization which aims to improve
                population health at the global and national levels. Here at GPHRF
                we deliver evidence in healthcare through high-quality research
                and promote healthy lifestyle. GPHRF is committed to enhancing
                research capacity to address broad challenges in . . .
              </p>
              <div class="about-btnn ">
                <div class="btn-box">
                  <Link to="/goals" className='btn bg-green text-white btn-2'>Apply</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 box ">
              <h2 className="h-text">
                Epidemiologist
              </h2>
              <p className="p-text">
                Vacancy Announcement Global Public Health Research Foundation
                (GPHRF) is a non-profit organization which aims to improve
                population health at the global and national levels. Here at GPHRF
                we deliver evidence in healthcare through high-quality research
                and promote healthy lifestyle. GPHRF is committed to enhancing
                research capacity to address broad challenges in . . .
              </p>
              <div class="about-btnn aplly">
                <div class="btn-box">
                  <Link to="/goals" className='btn bg-green text-white btn-2'>Apply</Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-1">
            <div className="col-12 col-md-6 box ">
              <h2 className="h-text">
                Public Health Advisor
              </h2>
              <p className="p-text">
                Vacancy Announcement Global Public Health Research Foundation
                (GPHRF) is a non-profit organization which aims to improve
                population health at the global and national levels. Here at GPHRF
                we deliver evidence in healthcare through high-quality research
                and promote healthy lifestyle. GPHRF is committed to enhancing
                research capacity to address broad challenges in . . .
              </p>
              <div class="about-btnn aplly">
                <div class="btn-box">
                  <Link to="/goals" className='btn bg-green text-white btn-2'>Apply</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 box ">
              <h2 className="h-text">
                Research Specialist- Institute for Global Health and Development
              </h2>
              <p className="p-text">
                Vacancy Announcement Global Public Health Research Foundation
                (GPHRF) is a non-profit organization which aims to improve
                population health at the global and national levels. Here at GPHRF
                we deliver evidence in healthcare through high-quality research
                and promote healthy lifestyle. GPHRF is committed to enhancing
                research capacity to address broad challenges in . . .
              </p>
              <div class="about-btnn aplly">
                <div class="btn-box">
                  <Link to="/goals" className='btn bg-green text-white btn-2'>Apply</Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Opportunities;
