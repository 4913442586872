import React from 'react'
import { Link } from 'react-router-dom';
import { MdArrowRightAlt } from "react-icons/md";
import Banner from '../Components/Banner';
import Nav from 'react-bootstrap/Nav';
import publication from "../imges/publications.png";


function Publications() {
  return (
    <div>
      <div>
        <Banner title={'Publications'} image={publication} />
      </div>
      <div>
        <div className='about-publication tabs'>
          <div className="custom-container">
            <Nav variant="pills" defaultActiveKey="/home">
              <Nav.Item>
                <Link href="/home" className='nav-link  nav-link-1' id='#all'>All</Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-1" className='nav-link'>2023</Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-2" className='nav-link' >2022</Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-3" className='nav-link'>2021</Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-4" className='nav-link' >2020-2019</Link>
              </Nav.Item>

            </Nav>
            <div className="row row-1">
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text" id='all'>
                  The Lancet Regional Health - <br /> Southeast Asia. 2023;
                  13
                </h3>
                <p className="p-text">
                  Rahman MM, Silva AD, Sassa M,
                  <span>
                    Islam MR, <br /> Aktar S, Akter S.
                  </span>{" "}
                  A systematic analysis and <br /> future projections of the
                  nutritional status and <br /> interpretation of its
                  drivers
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text">
                  The Lancet Regional Health-South Asia. 2023;
                  S277236822300104X.
                </h3>
                <p className="p-text">
                  <span> Aktar MS.</span> A systematic analysis and future{" "}
                  <br /> projections of the nutritional status and <br />{" "}
                  interpretation of its drivers among school-aged <br />{" "}
                  children in South-East Asian countries.
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text">
                  The Lancet Regional Health-South Asia. 2023;
                  S277236822300104X.
                </h3>
                <p className="p-text">
                  <span> Aktar MS.</span> A systematic analysis and future{" "}
                  <br /> projections of the nutritional status and <br />{" "}
                  interpretation of its drivers among school-aged <br />{" "}
                  children in South-East Asian countries.
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text">
                  Social Science & Medicine. 2022; 12.
                </h3>
                <p className="p-text">
                  Rahman MM, <span> Jung J, </span>Islam MR, Rahman MM,
                  Nakamura R, <span> Akter S,</span> Sato M. Global,
                  regional, and national progress in financial risk
                  protection towards universal health coverage, 2000–2030
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text">
                  Journal of Global Health. 2022; 1-12.
                </h3>
                <p className="p-text">
                  Rahman M, <span> Hossain F,</span> Islam R,{" "}
                  <span> Jung J, Mahmud SR,</span> Hashizume2 M. Equity in
                  antenatal care visits among adolescent mothers: An
                  analysis of 54 country levels trend.
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                <h3 className="h3-text">
                  Journal of Global Health. 2022; 1-12.
                </h3>
                <p className="p-text">
                  Rahman M, <span> Hossain F,</span> Islam R,{" "}
                  <span> Jung J, Mahmud SR,</span> Hashizume2 M. Equity in
                  antenatal care visits among adolescent mothers: An
                  analysis of 54 country levels trend.
                </p>
                <div className="euro-box">
                  {/* <img src={vector} alt="" /> */}
                  {/* <p className="text">Read Article</p> */}
                  <Link to="#pricing">
                    <MdArrowRightAlt
                      fontSize={24}
                      height={24}
                      color=" #151515"
                      width={24}
                    />
                  </Link>
                  <Link to="#pricing" className="text">
                    Read Article
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Publications