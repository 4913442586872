import React, { Component } from "react";
import gallery from "../imges/gallery.png";
import TeamSlide from "../Components/TeamSlide";
import NewsSlide from "../Components/NewsSlide";
import Headslide from "../Components/Headslide";
import { Link } from "react-router-dom";
import { MdArrowRightAlt } from "react-icons/md";
import "../home.css";

function Home() {
  return (
    <>
      <div className="parent-class">
        <Headslide />
        <section className="about-publication">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-4">
                <div className="about-textt">
                  <img src={gallery} alt="" />
                  <h2 className="about-head text-green">Our Publications</h2>
                  <p className="about-para">
                    Few of our publications are listed below.You <br />
                    can also find more of our publications on <br /> different
                    category from the Publication <br /> menu.
                  </p>
                  <Link
                    to="/"
                    href=""
                    className="btn bg-green text-white btn-2 "
                  >
                    More Publications
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-8">
                <div className="row row-1">
                  <div className="col-12 col-md-6 text-box">
                    <h3 className="h3-text">
                      The Lancet Regional Health - <br /> Southeast Asia. 2023;
                      13
                    </h3>
                    <p className="p-text">
                      Rahman MM, Silva AD, Sassa M,
                      <span>
                        Islam MR, <br /> Aktar S, Akter S.
                      </span>{" "}
                      A systematic analysis and <br /> future projections of the
                      nutritional status and <br /> interpretation of its
                      drivers
                    </p>
                    <div className="euro-box">
                      <Link to="/">
                        <MdArrowRightAlt
                          fontSize={24}
                          height={24}
                          color=" #151515"
                          width={24}
                        />
                      </Link>
                      <Link to="/" className="text">
                        Read Article
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-box">
                    <h3 className="h3-text">
                      The Lancet Regional Health-South Asia. 2023;
                      S277236822300104X.
                    </h3>
                    <p className="p-text">
                      <span> Aktar MS.</span> A systematic analysis and future{" "}
                      <br /> projections of the nutritional status and <br />{" "}
                      interpretation of its drivers among school-aged <br />{" "}
                      children in South-East Asian countries.
                    </p>
                    <div className="euro-box">
                      {/* <img src={vector} alt="" />
            <p className="text">Read Article</p> */}
                      <Link to="/">
                        <MdArrowRightAlt
                          fontSize={24}
                          height={24}
                          color="#151515"
                          width={24}
                        />
                      </Link>
                      <Link to="/" className="text">
                        Read Article
                      </Link>
                    </div>
                  </div>
                  {/* <div className="col-6"></div> */}
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 text-box">
                    <h3 className="h3-text">
                      Social Science & Medicine. 2022; 12.
                    </h3>
                    <p className="p-text">
                      Rahman MM, <span> Jung J, </span>Islam MR, Rahman MM,
                      Nakamura R, <span> Akter S,</span> Sato M. Global,
                      regional, and national progress in financial risk
                      protection towards universal health coverage, 2000–2030
                    </p>
                    <div className="euro-box">
                      {/* <img src={vector} alt="" />
            <p className="text">Read Article</p> */}
                      <Link to="/">
                        <MdArrowRightAlt
                          fontSize={24}
                          height={24}
                          color="#151515"
                          width={24}
                        />
                      </Link>
                      <Link to="/" className="text">
                        Read Article
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-box">
                    <h3 className="h3-text">
                      Journal of Global Health. 2022; 1-12.
                    </h3>
                    <p className="p-text">
                      Rahman M, <span> Hossain F,</span> Islam R,{" "}
                      <span> Jung J, Mahmud SR,</span> Hashizume2 M. Equity in
                      antenatal care visits among adolescent mothers: An
                      analysis of 54 country levels trend.
                    </p>
                    <div className="euro-box">
                      <Link to="/">
                        <MdArrowRightAlt
                          fontSize={24}
                          height={24}
                          color="#151515"
                          width={24}
                        />
                      </Link>
                      <Link to="/" className="text">
                        Read Article
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <TeamSlide />

      <div className="section-about">
        <div className="custom-container">
          <section className="about-news  text-white">
            <div className="text-box">
              <h3>
                Subscribe Our Newsletter To Get Update <br /> <br />
                News And Event Details
              </h3>
            </div>
            <div className="btn-box">
              <Link to="/" className="btn btnn bg-white text-green">
                SUBSCRIBE
              </Link>
            </div>
          </section>
        </div>
      </div>

      <NewsSlide />
    </>
  );
}

export default Home;
