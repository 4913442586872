
import boy1 from "../imges/boy1.png";
import boy2 from "../imges/boy2.png";
import boy3 from "../imges/boy2.png";
import girl from "../imges/girl.png";

//team
import ateeb from "../imges/data/team/ateeb.jpg";
import ayesha from "../imges/data/team/ayesha.jpg";
import faizan from "../imges/data/team/faizan.jpg";
import misbah from "../imges/data/team/misbah.jpg";
import musedqa from "../imges/data/team/musedqa.jpg";
import zujaja from "../imges/data/team/zujaja.jpg";
import shakir from "../imges/data/team/shakir.jpg";
import khadija from "../imges/data/team/khadija.jpg";
import tehreem from "../imges/data/team/tehreem.jpg";
import huma from "../imges/data/team/huma.png";
import eisha from "../imges/data/team/eisha.jpg";
import aqib from "../imges/data/team/aqib.jpg";
import subhan from "../imges/data/team/subhan.jpg";
import maham from "../imges/data/team/maham.jpg";

//exective members
import nawaz from "../imges/data/executive/nawaz.jpg";
import abdul from "../imges/data/executive/abdul.jpg";
import bilal from "../imges/data/executive/bilal.jpg";
import hameed from "../imges/data/executive/hameed.jpg";
import faisal from "../imges/data/executive/faisal.jpg";
import shahbaz from "../imges/data/executive/shahbaz.jpg";

// collaboartors
import hospital from "../imges/data/collaborator/hospital.jpg";

//donors
import weiye from "../imges/data/donor/weiye.jpg";

//goals
import TwoChildrens from "../imges/data/goal/TwoChildrens.jpg";
import teaching from "../imges/data/goal/teaching.jpg";
import plant from "../imges/data/goal/plant.jpg";
import EatingGirl from "../imges/data/goal/EatingGirl.jpg";


export const teamMember = [
    {
        name: 'Dr. Muhammad Ateeb',
        profession: '',
        about: '',
        image: ateeb
    },
    {
        name: '     Ayesha  Umar Chaudary ',
        profession: '',
        about: '',
        image: ayesha
    },
    {
        name: 'Faizan Ali Razzaq ',
        profession: '',
        about: '',
        image: faizan
    },
    {
        name: 'Misbah Jahangir',
        profession: '',
        about: '',
        image: misbah
    },
    {
        name: 'Musedqa Tahir',
        profession: '',
        about: '',
        image: musedqa
    },
    {
        name: 'Zujaja Eman',
        profession: '',
        about: '',
        image: zujaja
    },
    {
        name: 'Shakir Ali',
        profession: '',
        about: '',
        image: shakir
    },
    {
        name: 'Khadija Muqadas',
        profession: '',
        about: '',
        image: khadija
    },
    {
        name: 'Ayesha Tehreem Tariq',
        profession: '',
        about: '',
        image: tehreem
    },
    {
        name: 'Huma Khalid   ',
        profession: '',
        about: '',
        image: huma
    },
    {
        name: 'Amna Zaib    ',
        profession: '',
        about: '',
        image: huma
    },
    {
        name: 'Eisha Sahar',
        profession: '',
        about: '',
        image: eisha
    },
    {
        name: '     Muhammad Aqib    ',
        profession: '',
        about: '',
        image: aqib
    },
    {
        name: '     Muhammad Subhan Nazar',
        profession: '',
        about: '',
        image: subhan
    },
    {
        name: '        Maham saeed',
        profession: '',
        about: '',
        image: maham
    }
]


export const collaborators = [
    {
        name: 'Chaudhary Hospital',
        location: 'District Hari Pur',
        image: hospital
    }
]



export const donors = [
    {
        name: 'Weiye foods',
        about: '(PVT LTD )',
        image: weiye
    }
]



export const goals = [
    {
        name: 'Weiye foods',
        about: '(PVT LTD )',
        image: TwoChildrens
    },
    {
        name: 'Weiye foods',
        about: '(PVT LTD )',
        image: teaching
    },
    {
        name: 'Weiye foods',
        about: '(PVT LTD )',
        image: plant
    },
    {
        name: 'Weiye foods',
        about: '(PVT LTD )',
        image: EatingGirl
    }
]



export const executive = [
    {
        name: 'Dr. Allah Nawaz',
        profession: '',
        about: 'Harward University USA',
        image: nawaz
    },
    {
        name: 'Dr. Abdul Hayee',
        profession: '',
        about: 'PhD Toyama University Japan',
        image: abdul
    },
    {
        name: 'Dr. Muhammad Bilal',
        profession: '',
        about: 'PhD Toyama University Japan ',
        image: bilal
    },
    {
        name: ' Muhammad Hameed Iqbal',
        profession: '',
        about: 'CFA, LLB',
        image: hameed
    },
    {
        name: 'Dr. Faisal Afridi',
        profession: '',
        about: 'Public Health Expert',
        image: faisal
    },
    {
        name: ' Dr. Shahbaz Ahmed Zakki',
        profession: '',
        about: 'Public Health Educationist',
        image: shahbaz
    }
]



export const advisorsData = [
    {
        name: 'Jackson Gill (MBBS)',
        designation: 'Doctor',
        description: `Biostatistics, School of Public Health and Preventive
        Medicine, Monash University,Australia`,
        image: boy1
    },
    {
        name: 'Jackson Gill (MBBS)',
        designation: 'Doctor',
        description: `Biostatistics, School of Public Health and Preventive
        Medicine, Monash University,Australia`,
        image: boy2
    },
    {
        name: 'Jackson Gill (MBBS)',
        designation: 'Sergon',
        description: 'Doctor',
        image: boy3
    },
    {
        name: 'Jackson Gill (MBBS)',
        designation: 'Doctor',
        description: `Biostatistics, School of Public Health and Preventive
        Medicine, Monash University,Australia`,
        image: girl
    }
]


