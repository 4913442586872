import Header from './Components/Header';
import Footer from './Components/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Goal from './Pages/Goal';
import Membership from './Pages/Membership';
import Programs from './Pages/Programs';
import Womens from './Pages/Womens';
import Infection from './Pages/Infection';
import TopBar from './Components/TopBar';
import Opportunities from './Components/Opportunities';
import ContactUs from './Pages/ContactUs';
import Donate from './Pages/Donate';
import Publications from './Pages/Publications';
import BecomeAMember from './Pages/BecomeAMember';
import Advisors from './Pages/Advisors';
import Executive from './Pages/Executive';
import Professional from './Pages/Professional';
import Collaborator from './Pages/Collaborator';
import Donors from './Pages/Donors';
import NewsAndEvents from './Pages/NewsAndEvents';
import NewsDetails from './Pages/NewsDetails';
import Projects from './Pages/Projects';


function App() {
  return (
    <div>
      <TopBar />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/advisors' element={<Advisors />} />
        <Route path='/goals' element={<Goal />} />
        <Route path='/membership' element={<Membership />} />
        <Route path='/programs' element={<Programs />} />
        <Route path='/womens' element={<Womens />} />
        <Route path='/infection' element={<Infection />} />
        <Route path='/opportunities' element={<Opportunities />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/publications' element={<Publications />} />
        <Route path='/becomeAMember' element={<BecomeAMember />} />
        <Route path='/executive' element={<Executive />} />
        <Route path='/professional' element={<Professional />} />
        <Route path='/collaborator' element={<Collaborator />} />
        <Route path='/donors' element={<Donors />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='/newsandevents' element={<NewsAndEvents />} />
        <Route path='/newsdetails/:id' element={<NewsDetails />} />
        <Route path='/projects' element={<Projects />} />

      </Routes>
      <Footer />
    </div>
  )
}

export default App
