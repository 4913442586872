import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { advisorsData } from "../data/teamMembers";

function AdviserSlide() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="adviser-slide bg-grey color-c">
      <div className="slide-section">
        <div className="slider-container">
          <div className="custom-container">
            <h2 className="heading ">Board Of Advisors</h2>

            <Slider {...settings}>
              {advisorsData.map((item, index) => {
                return (
                  <div className="card bg-grey">
                    <img src={item.image} alt="" />
                    <div className="card-body">
                      <h3 className="img-text">{item.name}</h3>
                      <p className="img-para">{item.designation}</p>
                      <p className="text">
                        {item.description}
                      </p>
                    </div>
                  </div>
                )
              })}



            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdviserSlide;
