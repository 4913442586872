import React from "react";

// import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import boy1 from "../imges/boy1.png";
import { donors } from "../data/teamMembers";

function DonorsSlide() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="adviser-slide ">
      <div className="slide-section  ">
        <div className="slider-container">
          <div className="custom-container">
            <h2 className="heading ">Donors</h2>
            <Slider {...settings}>
              {donors.map((item, index) => {
                return (
                  <div className="card">
                    <img src={item.image} className="d-img"  alt="" />
                    <div className="card-body">
                      <h3 className="img-text">{item.name}</h3>
                      <p className="img-para">{item.about}</p>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DonorsSlide;
