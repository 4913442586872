import React from "react";
import { Link } from 'react-router-dom';
import Banner from "../Components/Banner";
import contact from "../imges/contact.png";




function ContactUs() {
  return (
    <div>
      <Banner title={'Contact Us'} image={contact} />

      <div className="form-section">
        <form action="">
          <div className="custom-container">
            <h2 className="f-text text-green">
              Contact Us
            </h2>
            <div className="row row-1">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <label for="inputEmail4" className="form-label">FIRST NAME</label>
                <input type="email" className="form-control" placeholder="First name" id="inputEmail4" />
              </div>
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <label for="inputPassword4" className="form-label">LAST NAME</label>
                <input type="password" className="form-control" placeholder="Last name" id="inputPassword4" />
              </div>
            </div>
            <div className="row row-1">
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <label for="inputEmail4" className="form-label">Email Address</label>
                <input type="email" className="form-control" placeholder="Email" id="inputEmail4" />
              </div>
              <div class="col-12 col-md-6 mb-3 mb-md-0">
                <label for="inputPassword4" className="form-label">Subject</label>
                <input type="password" className="form-control" placeholder="Subject" id="inputPassword4" />
              </div>
            </div>
            <div className="col-12">
              <label for="floatingTextarea2" className="form-label">Your Message</label>
              <textarea class="form-control t-area" placeholder="Your Message Here..." id="floatingTextarea2" ></textarea>
            </div>
            <div class="about-btnn ">
              <div class="btn-box">
                <Link to="/goals" className='btn bg-green text-white btn-2'>Send Message</Link>
              </div>
            </div>
          </div>
          <div />
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
