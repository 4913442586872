import React from "react";
import TwoCHILDRENS from "../imges/data/goal/TwoChildrens.jpg";
import teaching from "../imges/data/goal/teaching.jpg";
import plant from "../imges/data/goal/plant.jpg";
import EatingGirl from "../imges/data/goal/EatingGirl.jpg";

import Banner from "../Components/Banner";
import goals from "../imges/goals.png";

function Goal() {
  return (
    <div>
      

      <Banner title={"Our Goals"}  image={goals}/>

      <div>
        <div className="textimg">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="text-box">
                  <h3 className="heading"> END POVERTY</h3>
                  <p className="text">
                    "A world free from the shackles of poverty is our unwavering vision. Our foundation is committed to pioneering transformative solutions that eradicate poverty and create a future where every individual, regardless of their circumstances, enjoys dignity, opportunity, and prosperity. Guided by principles of equity, collaboration, and sustainable development, we envision a global community where the cycle of poverty is broken, and all people have the resources and support needed to thrive and build a better tomorrow."
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="img-box">
                  <img src={TwoCHILDRENS} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="textimg">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="img-box">
                  <img src={EatingGirl} className="w-100" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-box">
                  <h3 className="heading">ZERO HUNGER</h3>
                  <p className="text">
                    "In pursuit of a hunger-free world, our foundation envisions a future where every person has access to nutritious food, ensuring not only sustenance but also the opportunity for vibrant and healthy lives. We are committed to pioneering innovative solutions, fostering collaboration, and advocating for systemic change to eradicate hunger globally. Grounded in principles of compassion, sustainability, and inclusivity, our vision is a world where no one goes to bed hungry, and communities thrive with shared abundance and well-being."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="textimg">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="text-box">
                  <h3 className="heading">Good Health and Well-Being </h3>
                  <p className="text">
                    "Our foundation envisions a world where good health and well-being are universally accessible, empowering every individual to lead fulfilling lives. Rooted in a commitment to preventive care, innovation, and equitable healthcare systems, we strive to eliminate barriers to well-being. Our vision embraces a global community where physical and mental health are prioritized, enabling individuals to reach their full potential, communities to thrive, and societies to flourish with vitality and resilience."
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="img-box">
                  <img src={plant} className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="textimg">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="img-box">
                  <img src={teaching} className="w-100" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="text-box">
                  <h3 className="heading">Quality Education:</h3>
                  <p className="text">
                    "WE work to obtain a world where quality education is a fundamental right, empowering every learner to unlock their full potential. Committed to excellence, innovation, and inclusivity, we strive to eradicate barriers to education, ensuring that every individual, regardless of background or circumstance, has access to enriching and transformative learning experiences. Our vision extends beyond classrooms to foster a global community where education becomes a catalyst for positive change, equipping individuals with the knowledge and skills to build a brighter and more equitable future."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="about-btnn">
          <div className="btn-box">
            <Link
              to="#pricing"
              href=""
              className="btn bg-green text-white btn-2 "
            >
              View More
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Goal;
