import React from "react";
import Banner from "../Components/Banner";
import Collaborators from "../Components/Collaborators";

function Collaborator() {
  return (
    <div>
      {/* <div className="b">
        <Banner />
      </div> */}
      <Collaborators />
    </div>
  );
}

export default Collaborator;