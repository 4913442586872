import React from "react";
import { TiTick } from "react-icons/ti";
import Banner from "../Components/Banner";
import Account from "./Donate";
import { Link } from "react-router-dom";
import membership from "../imges/membership.png";



function BecomeAMember() {
  return (
    <div>
      <div>
        <Banner title={"Become A Member"}  image={membership}/>
      </div>

      <div>
        <div className="category">
          <div className="custom-container">




          <div className="col-12">
            {/* <div className="custom-container"> */}
              <div className="catagory-section">
                <h2 className="catagory-text text-green">
                  Why you should get our membership?
                </h2>

                <h4 className="nav-link l-text">
                  Becoming a member of an organization entails networking with
                  public health experts, gaining new certifications, receiving
                  advice from specialists and authorities, and being part of a
                  respected group in your field.
                </h4>
              </div>
            {/* </div> */}
          </div>





            <div className="catagory-section">
              <h2 className="catagory-text text-green">
                Categories of Membership
              </h2>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <ul className="list">
                    <li>
                      <h2 className="nav-link drop-btn bt-1">
                        <TiTick color="black" className="text-1" />
                        Student Member
                      </h2>
                      <h2 className="nav-link drop-btn">
                        <TiTick color="black" className="text-1" />
                        Chartered Member (CMIOSH)
                      </h2>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <ul className="list">
                    <li>
                      <h2 className="nav-link drop-btn bt-1">
                        <TiTick color="black" className="text-1" />
                        Affiliate Member
                      </h2>
                      <h2 className="nav-link drop-btn">
                        <TiTick color="black" className="text-1" />
                        Chartered Fellow (CFIOSH)
                      </h2>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <ul className="list">
                    <li>
                      <h2 className="nav-link drop-btn">
                        <TiTick color="black" className="text-1" />
                        Technical Member (TechIOSH)
                      </h2>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <ul className="list">
                    <li>
                      <h2 className="nav-link drop-btn">
                        <TiTick color="black" className="text-1" />
                        Graduate Member (GradIOSH)
                      </h2>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

       

          <div className="col-12">
            <div className="custom-container">
              <div className="want-to-get">
                <h2 className="catagory-text text-green">MEMBERSHIP :</h2>
                <h3 className="member-text">Want to get our membership</h3>
                <ul className="bank-list list-style-none ps-0">
                  <li>
                    <Link
                      to="https://docs.google.com/forms/d/e/1FAIpQLSdOHxWMlNbRsj9RM0Toui82KjADljTJXOKs_Hv4UI13G5s5Ew/viewform?usp=sf_link"
                      className="m-link" target="blank">
                      https://docs.google.com/forms/d/e/1FAIpQLS
                      dOHxWMlNbRsj9RM0Toui82KjADljTJXOKs_Hv4
                      UI13G5s5Ew/viewform?usp=sf_link
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BecomeAMember;
