

import lecture from "../imges/data/programs/lecture.jpg";
import flex from "../imges/data/programs/flex.jpg";
import study from "../imges/data/programs/study.jpg";
import education from "../imges/data/programs/education.jpg";
import chart from "../imges/data/newsandevents/chart.jpg";
import team from "../imges/data/newsandevents/team.jpg";

export const homeSlideData = [
    {
        id: '1',
        title: 'Women Health Empowerment',
        description: 'Breast cancer awareness session in school',
        image: lecture
    },
    {
        id: '2',
        title: 'Awareness Session',
        description: 'Awareness on breast cancer session in UOH',
        image: flex
    },
    {
        id: '3',
        title: 'School Health',
        description: 'Our foundation envisions a future where every ',
        image: chart
    },
    {
        id: '4',
        title: 'Health Education',
        description: 'education serves as a fundamental pillar  in every community',
        image: team
    }

]
