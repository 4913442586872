import React from 'react'
import Banner from '../Components/Banner'
import donation from "../imges/donation.png";

function Donate() {
  return (
    <div>
      <div>
        <Banner title={"Donate"} image={donation} />
      </div>
      <div className="custom-container">
        <div className="account accountt">
          <h2 className="catagory-text text-green">
            ACCOUNT:
          </h2>
          <h3 className='bank'>
            Meezan Bank

          </h3>
          <ul className='bank-list'>
            <li>
              <p className="text">Account Title: Shahbaz Ahmad Zakki</p>
            </li>
            <li>
              <p className="text">Account Number: 32810105353658</p>
            </li>
            <li>
              <p className="text">IBAN: PK36MEZN0032810105353658</p>
            </li>
          </ul>
          <h3 className='bank'>
          JAZZ Cash

          </h3>
          <ul className='bank-list'>
            <li>
              <p className="text">Account Title: Shahbaz Ahmad Zakki</p>
            </li>
            <li>
              <p className="text">Account Number: 03336362383</p>
            </li>
           
          </ul>


        </div>
      </div>
    </div>
  )
}

export default Donate