import React from "react";
import goals from "../imges/programs.png";
import Hero from "../Components/Hero";
import { programsData } from "../data/programsData";
import Banner from "../Components/Banner";

function Goal() {
  return (
    <div>
      

      <Banner title={"Our Programs"}  image={goals}/>



      <div>
        {programsData.map((item, index) => {
          return (
            <Hero side={index % 2 == 0 ? "left" : 'right'} data={item} />
          )
        })}
      </div>
    </div>
  );
}

export default Goal;
