import React, { useEffect, useState } from 'react'
import Banner from '../Components/Banner';
import Carousel from 'react-bootstrap/Carousel';
import { useParams } from 'react-router-dom';
import { newsData } from '../data/newsData';



function NewsDetails({ }) {

  const [newsItem, setNewsItem] = useState(null)
  const { id } = useParams();

  useEffect(() => {
    console.log('iddd', id)
    console.log('news ', newsData.find(item => item.id === id))
    setNewsItem(newsData.find(item => item.id === id))
  }, [id])

  return (
    <div>
      <div>
        <Banner title={'Latest News & Events'} />
      </div>

      {newsItem !== null &&
        <div className='nevents'>
          <div className='newsevents'>
            <div className="custom-container">
              <h2 className="heading-text ">{newsItem?.title}</h2>
              <Carousel>
                {(newsItem?.image || []).map(image => {
                  return (
                    <Carousel.Item>
                      <img src={image} className='w-100 nd-img' alt="" />
                    </Carousel.Item>
                  )
                })}
              </Carousel>

              <div className="col-12 col-sm-7 col-md-8">
                <p class="img-para desc">{newsItem?.description}</p>
              </div>

            </div>
          </div>
        </div>
      }
      {newsItem == null &&
        <div>
          <p>No details found</p>
        </div>
      }
    </div>
  )
}

export default NewsDetails