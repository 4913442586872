import React from "react";
import Card from "react-bootstrap/Card";
import meeting from "../imges/meeting.png";
import mission from "../imges/mission.png";
import hand from "../imges/hand.png";
import objective from "../imges/objective.png";
import vision from "../imges/vision.png";
import object from "../imges/object.png";
import about from "../imges/about.png";
import Banner from "../Components/Banner";

function About() {
  return (
    <div>
      <div className="b">
        <Banner title={'About'} image={about} />

        <section className="card-section">
          <div className="custom-container">
            <div className="row">
              <div className="col-12">
                <Card.Body>
                  {/* <Card.Title>Our Vision</Card.Title> */}
                  <div className="heading-sec">
                    <h3 className="heading text-green pt-20">Our Vision</h3>
                  </div>
                  <Card.Text>
                    <ol>
                      <li>
                        Our vision is to create a world where every person avail
                        equal opportunity, lives without poverty and hunger, enjoys
                        good health and well-being, receives quality education,
                        experiences gender equality, has access to clean water and
                        sanitation, engages in decent work, and lives in a
                        sustainable environment with effective climate actions. In
                        broader terms we envision to be a globally recognized
                        research organization that drives innovation, shapes the
                        future, and positively impacts society through
                        evidence-based research.
                      </li>
                    </ol>
                  </Card.Text>
                </Card.Body>
                <div className="green"></div>
              </div>

              <div className="col-12">
                <Card.Body>
                  {/* <Card.Title>Our Mission</Card.Title> */}
                  <div className="heading-sec">
                    <h3 className="heading text-green pt-20">Our Mission</h3>
                  </div>
                  <Card.Text>
                    <ol>
                      <li>


                        Our mission is to effectively utilize and promote research
                        to empower individuals and communities to reduce poverty by
                        promoting good health and improving educational quality
                        standards. It also includes promoting gender equality,
                        accessibility to clean water and sanitation, focusing
                        economic growth and considering climate changes by creating
                        awareness through cutting edge research, innovation,
                        collaboration, and community work.
                      </li>
                      <li>


                        Our mission is also to
                        conduct high-quality research, explore new frontiers of
                        knowledge, and translate our findings into practical
                        solutions for the betterment of humanity. We strive to
                        collaborate with diverse stakeholders and create a nurturing
                        environment that encourages interdisciplinary research,
                        fosters creativity, and promotes the application of research
                        outcomes.
                      </li>
                    </ol>
                  </Card.Text>
                </Card.Body>
                <div className="green"></div>
              </div>

              <div className="col-12">
                <Card.Body>
                  {/* <Card.Title>Our Objective</Card.Title> */}
                  <div className="heading-sec">
                    <h3 className="heading text-green pt-20">Our Objective</h3>
                  </div>
                  <Card.Text>
                    <ol>
                      <li>

                        Reduce poverty rates by implementing social welfare
                        programs, job creation initiatives, and skill development
                        programs.

                      </li>
                      <li>
                        Enhance agricultural productivity, improve rural
                        infrastructure, and provide support for small-scale farmers
                        to achieve food security.
                      </li>
                      <li>
                        Strengthen healthcare systems,
                        improve access to essential healthcare services, and promote
                        preventive healthcare measures.
                      </li>
                      <li>
                        Enhance educationalinfrastructure, train teachers, and promote inclusive and
                        quality education for all.
                      </li>
                      <li>
                        Promote gender equality through
                        legislation, empower women economically and politically, and
                        eliminate gender-based violence.
                      </li>
                      <li>
                        Improve water management
                        systems, increase access to clean water, and promote
                        sanitation and hygiene practices.
                      </li>
                      <li>
                        Foster inclusive economic
                        growth by supporting entrepreneurship, providing vocational
                        training, and creating decent job opportunities.
                      </li>
                      <li>
                        Reduce greenhouse gas emissions, promote renewable energy sources,
                        and encourage sustainable practices across industries and communities.
                      </li>
                    </ol>
                  </Card.Text>
                </Card.Body>
                <div className="green"></div>
              </div>

              {false && (
                <>
                  <div className="col-12">
                    <Card>
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <div className="box">
                        <div className="img-box">
                          <img src={meeting} alt="" />
                        </div>
                        <div className="img-2">
                          <img src={mission} alt="" />
                        </div>
                      </div>

                      <Card.Body>
                        <Card.Title>Our Mission</Card.Title>
                        <Card.Text>
                          Lorem ipsum dolor sit amet, consectetur <br />{" "}
                          adipiscing elit. Nunc vitae porttitor nunc. <br />{" "}
                          Vivamus vel sem lacinia, ornare neque vel, <br />{" "}
                          maximus felis.
                        </Card.Text>
                      </Card.Body>
                      <div className="green"></div>
                    </Card>
                  </div>
                  <div className="col-12">
                    <Card>
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <div className="box">
                        <div className="img-box">
                          <img src={hand} alt="" />
                        </div>
                        <div className="img-2">
                          <img src={vision} alt="" />
                        </div>
                      </div>

                      <Card.Body>
                        <Card.Title>Our Mission</Card.Title>
                        <Card.Text>
                          Lorem ipsum dolor sit amet, consectetur <br />{" "}
                          adipiscing elit. Nunc vitae porttitor nunc. <br />{" "}
                          Vivamus vel sem lacinia, ornare neque vel, <br />{" "}
                          maximus felis.
                        </Card.Text>
                      </Card.Body>
                      <div className="green"></div>
                    </Card>
                  </div>
                  <div className="col-12">
                    <Card>
                      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                      <div className="box">
                        <div className="img-box">
                          <img src={objective} alt="" />
                        </div>
                        <div className="img-2">
                          <img src={object} alt="" />
                        </div>
                      </div>

                      <Card.Body>
                        <Card.Title>Our Mission</Card.Title>
                        <Card.Text>
                          Lorem ipsum dolor sit amet, consectetur <br />{" "}
                          adipiscing elit. Nunc vitae porttitor nunc. <br />{" "}
                          Vivamus vel sem lacinia, ornare neque vel, <br />{" "}
                          maximus felis.
                        </Card.Text>
                      </Card.Body>
                      <div className="green"></div>
                    </Card>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default About;
