import React from "react";
import Banner from "../Components/Banner";
import Card from "react-bootstrap/Card";
import membership from "../imges/membership.png";
import humans from "../imges/humans.jpg";
import { teamMember } from "../data/teamMembers";

function Membership() {
  return (
    <div className="meeting banner ">
      {/* <img src={computer} className="w-100 b-image" alt="" />
      <div className="custom-container">
        <div className="banner meeting position-relative">
          <div className="baner position-absolute">
            <div className="row">
              <div className="col-12">
                <div className="b-box">
                  <div className="img-box">
                    <img src={pause} alt="" />
                  </div>
                  <div className="text-box">
                    <p className="p-text">Membership</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Banner title={"Become A Member"} image={humans} />

      <div className="heading-sec">
        <div className="h-box-1">
          <div className="custom-container">
            <h3 className="heading text-green">General Members</h3>
          </div>
        </div>
      </div>



      <div className="h-box-2">
        <div className="about-card-section">
          <div className="custom-container">
            <div className="row">
              {teamMember.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 ">
                    <Card className="text-center">
                      <img src={item.image} alt="" />
                      <Card.Body>
                        <Card.Title className="color-blue">
                          {""}
                          {item.name}
                        </Card.Title>
                        <div className="card-para color-blue">
                          {""}
                        </div>
                        {/* <Card.Text className="color-blue">
                          Biostatistics, School of Public Health <br /> and
                          Preventive Medicine, Monash <br /> University,Australia
                        </Card.Text> */}
                      </Card.Body>
                    </Card>
                  </div>
                )
              })}


            </div>
          </div>
        </div>
        {/* <div class="about-btnn">
          <div class="btn-box">
            <Link to="/goals" className='btn bg-green text-white btn-2'>View More</Link>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Membership;
