import React from "react";
import Banner from "../Components/Banner";
import AboutSpeech from "../imges/data/projects/AboutSpeech.jpg";
import AboutHealth from "../imges/data/projects/AboutHealth.jpg";
import discussion from "../imges/data/projects/discussion.jpg";
import StudentDiscussion from "../imges/data/projects/StudentDiscussion.jpg";
import UniLec from "../imges/data/projects/Uni-Lec.jpg";
import UniTeam from "../imges/data/projects/UniTeam.jpg";
import weight from "../imges/data/projects/weight.jpg";
import size from "../imges/data/projects/size.jpg";
import bp from "../imges/data/projects/bp.jpg";


function Projects() {
  return (
    <div>
      <div>
        <Banner title={"Projects"} />
      </div>
      <div className="project">
        <div className="col-12">
          <div className="custom-container">
            <h2 className="project-heading">
              Project on Prevalence of Young Carers and Multidimensional
              assessment of their Caregiv ing Responsibilities
            </h2>
            <p className="pro-para">
              Exploring mental health challenges in young carers due to their
              care giving responsibilities: a randomized controlled trail (RCTR)
              assessing the feasibility of implementing WHO self-help SH+
              interventions in Pakistani educational contexts.
            </p>
            <p className="pro-para">
              Health Awareness and Health research - A project related activity
              in the distant population of District Haripur.
            </p>
            <p className="pro-para">
              - Underprivileged community in far flung and hard to access areas
              of District Haripur.
            </p>
          </div>
        </div>

        <div className="pro-img">
          <div className="custom-container">
            <div className="row row-1">
              <div className="col-12 col-sm-6">
                <img src={AboutSpeech} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={AboutHealth} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={discussion} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={StudentDiscussion} className="w-100 mb-4" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="custom-container">
            <h2 className="project-heading">
            Project on prevalence of anxiety and depression among university students 
            </h2>
          
          </div>
        </div>

        <div className="pro-img">
          <div className="custom-container">
            <div className="row row-1">
              <div className="col-12 col-sm-6">
                <img src={UniLec} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={UniTeam} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={weight} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={size} className="w-100 mb-4" alt="" />
              </div>
              <div className="col-12 col-sm-6">
                <img src={bp} className="w-100 mb-4" alt="" />
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>
  );
}

export default Projects;
