import React from "react";
import pause from "../imges/pause.png";
import banner from "../imges/banner.jpg";


function Banner({ title, image }) {


  // add background image here 
  return (
    <div className="banner  position-relative">
      <img src={image ? image : banner} className="w-100 b-image" alt="" />
      <div className="custom-container">

        <div className="baner position-absolute">

          <div className="row">
            <div className="col-12">
              <div className="b-box">
                <div className="img-box">
                  <img src={pause} alt="" />
                </div>
                <div className="text-box">
                  <p className="p-text">{title ?? 'Aqeeq Foundation'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
