import React from "react";
import Banner from "../Components/Banner";
import Professionals from "../Components/Professionals";

function Professional() {
  return (
    <div>
      <div className="b">
        <Banner />
      </div>
      <Professionals />
    </div>
  );
}

export default Professional;