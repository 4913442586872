import React from "react";
import Childrens from "../imges/childrens.png";
import WalkingGirl from "../imges/WalikingGirl.png";

function Hero({ side, data }) {
  if (side === "right")
    return (
      <div className="textimg">
        <div className="custom-container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="text-box">
                <h3 className="heading">{data.title}</h3>
                <p className="text">
                 {data.description}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="img-box">
                <img src={data.image} className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="textimg">
        <div className="custom-container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="img-box">
                <img src={data.image} className="w-100" alt="" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="text-box">
                <h3 className="heading">{data.title} </h3>
                <p className="text">
                  {data.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Hero;
