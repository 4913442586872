import React from "react";
import Banner from "../Components/Banner";
// import Text from '../Components/Hero'
import Hero from "../Components/Hero";
// import { Link } from 'react-router-dom';

function Infection() {
  return (
    <div>
      <div>
        <Banner />
      </div>
      <div>
        <Hero side={'left'}  />
        <Hero  side={'right'} />
      </div>
    </div>
  );
}

export default Infection;
